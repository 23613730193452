import { axiosInstance, setAccessToken } from "./axios";
import store from "@/store";
import { decodeCredential } from "vue3-google-login";
import { googleOneTap } from "vue3-google-login";
import { toaster } from "../utils/toast/toast";
import userModule from '../store/user/user.module.js';
/**
 
Login user by api
@param {Object} data
@param {Boolean} rememberMe
@return {Promise}
@throws {Error}*/
const login = async (data, rememberMe) => {
  try {
    const response = await axiosInstance.post("/user/token/", data);

    const token = response.data.access;
    // if (rememberMe) {
    setAccessToken(token);
    // };
    await store.dispatch('setToken', token); 
    await store.dispatch('fetchUser');
    return;
  } catch (error) {
    throw new Error(error.response.data?.detail || "Login failed");
  }
};

/**
 
Login user by google one tap
@returns {Promise}
@throws {Error}*/
const googleLogin = async () => {
  try {
    console.log("googleLogin");
    const response = await googleOneTap({
      clientId:
        "499109195466-3cgb1ucg8oon8ncdemjpveuqkv7n0i61.apps.googleusercontent.com",
      context: "signin",
    });

    const regData = decodeCredential(response.credential);

    // try to login
    const loginResponse = await login({
      email: regData.email,
      password: regData.sub,
    });
    // save token
    setAccessToken(loginResponse.data.access);

    // get user profile, and store it in vuex
    addUserToStore()
    return;
  } catch (error) {
    throw new Error(error.response.data.detail || "Login failed");
  }
};

/**
 
Register user
@param {Object} credentials
@returns {Promise}*/
const register = async (credentials) => {
  try {
    return await axiosInstance.post("/user/register/", credentials);
  } catch (error) {
    throw new Error({
      "error": error,
      "message": "Erreur lors de l'inscription",
    });
  }
};

/**
 * Logout user
 */
const logout = async () => {
  await axiosInstance.post("/user/log-out/");

  store.dispatch("logout");

  return window.location.href = "/";

};

const deleteAccount = async () => {
  try {
    toaster.showInfoPopup(
      "Votre compte est en cours de suppression. Vous allez être redirigé vers la page de connexion.",
    );

    await axiosInstance.delete("/user/");

    window.location.href = "/";

    return 
  } catch (error) {
    toaster.showErrorPopup();
    console.error("Error deleting user account:", error);
  }
};

// à deplacer dans un fichier user.js
const getUser = async () => {
  try {
    const response = await axiosInstance.get("/user/");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.detail || "User not found");
  }
};

const addUserToStore = async () => {
  try {
    const user = store.getters['getUser']; 
    if (!user) {
      throw new Error("User not found in store");
    }
    if (!user.type_user) {
      console.warn("User role is missing in the response");
      return; 
    }

    store.dispatch("handleUserChange", { type: null, payload: user });
    store.dispatch("handleUserRoleChange", user.type_user);
    store.commit("setLoggedIn", true);
  } catch {
    store.commit("setLoggedIn", false);
  }
};


export {
  addUserToStore,
  deleteAccount,
  getUser,
  googleLogin,
  login,
  logout,
  register,
};
